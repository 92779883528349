import { ref, computed } from "vue";
import { useStorage } from "@vueuse/core";
import { isEmpty } from "lodash-es";
import { isAllowLocalStorage } from "~/helpers/permissions";
import { useReport } from "~/composables/useReport";
import { getCities as fetchCities } from "~/api/common/city";
import type { City } from "~/types/City";

const allCityOption: City = {
  attributes: {
    name: "All Cities",
    homeDescription: "",
    icon: {
      url: "",
    },
    countryId: 0,
    countryIcon: {
      url: "",
    },
  },
  type: "cities",
  id: "",
};

const localStorageKey = "hungryhub_selected_city_id";
const defaultSelectedCityId = "1"; // Bangkok
const isLoading = ref(true);
const selectedCityId = isAllowLocalStorage()
  ? useStorage(localStorageKey, defaultSelectedCityId)
  : ref(defaultSelectedCityId);

const availableCities: City[] = [];
const selectedCity = computed(() => {
  if (
    isLoading.value === true ||
    !selectedCityId.value ||
    !availableCities ||
    isEmpty(availableCities)
  ) {
    return {
      attributes: {
        name: "",
        homeDescription: "",
      },
      type: "cities",
      id: 0,
    };
  }
  const filter = availableCities.filter(
    (city) => city.id === selectedCityId.value
  );
  return filter[0];
});

const selectedCityName = computed(() => {
  return selectedCity.value.attributes.name;
});

const selectedCityHomeDescription = computed(() => {
  return selectedCity.value.attributes.name;
});

export async function getCities(): Promise<{
  isSuccess: boolean;
  data: null | City[];
  message: string;
}> {
  isLoading.value = true;
  const { isSuccess, data, message } = await fetchCities();

  if (!isSuccess) {
    useReport({
      level: "error",
      message,
    });
    return {
      isSuccess: false,
      message,
      data: null,
    };
  }

  availableCities.push(allCityOption);
  data?.forEach((city) => {
    availableCities.push(city);
  });
  isLoading.value = false;

  return {
    isSuccess: true,
    message: "",
    data,
  };
}

export {
  selectedCity,
  selectedCityId,
  selectedCityName,
  selectedCityHomeDescription,
  isLoading,
  availableCities,
};
