import { defineStore } from "pinia";
import type { City } from "~/types/City";
import { getCities } from "~/services/common/getCities";
import { THAILAND_COUNTRY_ID } from "~/constants";

const defaultCity: City = {
  id: "1",
  type: "cities",
  attributes: {
    name: "Bangkok",
    homeDescription: "",
    countryId: THAILAND_COUNTRY_ID,
    countryIcon: {
      url: "",
    },
    icon: {
      url: "",
    },
  },
};

const useCityStore = defineStore("city", {
  state: () => {
    return {
      defaultCity,
      selectedCityId: "1",
      selectedCountryId: THAILAND_COUNTRY_ID,
      cityOption: [] as City[],
    };
  },
  getters: {
    selectedCity(): City {
      const filter = this.cityOption.filter(
        (city) => city.id === this.selectedCityId
      );
      return filter.length ? filter[0] : defaultCity;
    },
    selectedCityName(): string {
      return this.selectedCity?.attributes.name || "";
    },
    isThCountry(): boolean {
      return this.selectedCountryId === THAILAND_COUNTRY_ID;
    },
    selectedCityHomeDescription(): string {
      return this.selectedCity?.attributes.homeDescription || "";
    },
  },
  actions: {
    async fetchCity() {
      const { isSuccess, data, message } = await getCities();
      if (isSuccess && data) {
        this.cityOption = data;
        return data;
      }
      throw new Error(message);
    },
  },
});

export default useCityStore;
